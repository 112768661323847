<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" persistent max-width="600px">
      <template v-slot:activator="{ on, attrs }">
        <v-btn color="pink" dark v-bind="attrs" v-on="on"> Add Expenses </v-btn>
      </template>
      <v-card>
        <v-card-title>
          <span class="text-h5"> Add Expenses</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="6" md="6">
                <v-text-field
                  v-model="amount"
                  shaped
                  filled
                  label="Amount*"
                  required
                  type="number"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-text-field
                  v-model="title"
                  shaped
                  filled
                  label="Title"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="12">
                <v-textarea
                  filled
                  auto-grow
                  label="Discription"
                  rows="4"
                  row-height="30"
                  shaped
                  v-model="description"
                ></v-textarea>
              </v-col>
            </v-row>
          </v-container>
          <small>*indicates required field</small>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="dialog = false">
            Close
          </v-btn>
          <v-btn dark color="primary" @click="AddExpense"> Save </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
export default {
  data: () => ({
    dialog: false,
    amount: "",
    title: "",
    description: "",
  }),

  computed: {
    getToken() {
      return this.$store.getters.getToken;
    },
  },

  methods: {
    AddExpense() {
      if (this.amount == "" || this.title == "") {
        this.$store.commit("setSnackbar", true);
        this.$store.commit(
          "setServerMessage",
          "Fill Out all the required fields"
        );
        return;
      }
      let payload = {
        token: this.getToken,
        amount: this.amount,
        title: this.title,
        description: this.description,
      };
      this.$store.dispatch("addExpense", payload);
      this.dialog = false;
      this.amount = "";
      this.title = "";
      this.description = "";
    },
  },
};
</script>