<template>
  <v-container>
    <v-card-actions>
      <v-spacer />
      <div>
        <AddInventory class="ma-2" />
      </div>
      <div>
        <AddExpenses class="ma-2" />
      </div>
    </v-card-actions>
    <v-tabs v-model="tab" background-color="info" dark>
      <v-tab v-for="item in items" :key="item.tab">
        {{ item.tab }}
      </v-tab>
    </v-tabs>
    <v-tabs-items v-model="tab">
      <v-tab-item v-for="item in items" :key="item.content">
        <v-card flat v-if="tab == 0 || tab == 2">
          <v-data-table
            :headers="dessertHeaders"
            :items="inventory"
            :expanded.sync="expanded"
            item-key="_id"
            show-expand
            :single-expand="true"
            hide-default-footer
            class="elevation-1"
            :loading="TableLoading"
            loading-text="Loading... Please wait"
          >
            <template v-slot:item.cost="{ item }">
              <span>{{ DisplayCurrency(item.cost) }}</span>
            </template>
            <template v-slot:item.createdAt="{ item }">
              <span>{{ DisplayTimeFormat(item.createdAt) }}</span>
            </template>
            <template v-slot:expanded-item="{ headers, item }">
              <td :colspan="headers.length" class="pa-4">
                <div>
                  {{ item.description }}
                </div>
                <v-divider class="ma-2" />
                <div>
                  <b> Added By : </b>
                  {{ item.addByStaff.personalInfo.firstName }}
                  {{ item.addByStaff.personalInfo.lastName }}
                </div>
                <v-card-actions>
                  <div v-if="item.assignTo">
                    <div :key="index" v-for="(assign, index) in item.assignTo">
                      <b> Assign To : </b>
                      {{ assign.personalInfo.firstName }}
                      {{ assign.personalInfo.lastName }}
                      <v-divider class="ma-1" />
                    </div>
                  </div>

                  <v-spacer></v-spacer>
                  <div>
                    <AssignStaffToInventory
                      v-if="tab == 0"
                      :inventoryId="item._id"
                    />
                  </div>
                </v-card-actions>
              </td>
            </template>
          </v-data-table>
        </v-card>
        <v-card flat v-if="tab == 1 || tab == 3">
          <v-data-table
            :headers="expenseHeaders"
            :items="expense"
            :expanded.sync="expanded"
            item-key="_id"
            show-expand
            :single-expand="true"
            hide-default-footer
            class="elevation-1"
            :loading="TableLoading"
            loading-text="Loading... Please wait"
          >
            <template v-slot:item.amount="{ item }">
              <span>{{ DisplayCurrency(item.amount) }}</span>
            </template>
            <template v-slot:item.createdAt="{ item }">
              <span>{{ DisplayTimeFormat(item.createdAt) }}</span>
            </template>
            <template v-slot:expanded-item="{ headers, item }">
              <td :colspan="headers.length" class="pa-4">
                <div>
                  {{ item.description }}
                </div>
                <v-divider class="ma-2" />
                <div>
                  <b> Added By : </b>
                  {{ item.addByStaff.personalInfo.firstName }}
                  {{ item.addByStaff.personalInfo.firstName }}
                </div>
                <div v-if="item.assignTo">
                  <b> Assign To : </b>
                  {{ item.assignTo.personalInfo.firstName }}
                  {{ item.assignTo.personalInfo.firstName }}
                </div>
              </td>
            </template>
          </v-data-table>
        </v-card>
      </v-tab-item>
    </v-tabs-items>
    <div class="text-center pt-2">
      <v-pagination v-model="page" :length="totalItems"></v-pagination>
    </div>
  </v-container>
</template>
<script>
import AddInventory from "../components/AddOfficeInventory.vue";
import AddExpenses from "../components/AddExpenses.vue";
import AssignStaffToInventory from "../components/AssignStaffToInventory.vue";
import moment from "moment";
import currencyFormatter from "currency-formatter";

export default {
  components: {
    AddInventory,
    AddExpenses,
    AssignStaffToInventory,
  },
  data() {
    return {
      page: 1,
      Currpage: 1,
      expanded: [],
      singleExpand: false,
      tab: null,
      items: [
        { tab: "Office Inventory", content: "Inventory" },
        { tab: "Office Expenses", content: "Office Expenses" },
        { tab: "All Inventory", content: "All Inventory" },
        { tab: "All Expenses", content: "All Expenses" },
      ],
      dessertHeaders: [
        {
          text: "Item Name",
          align: "start",
          value: "itemName",
        },
        { text: "Cost", align: "center", value: "cost" },
        { text: "Date", align: "center", value: "createdAt" },
      ],
      expenseHeaders: [
        {
          text: "Title",
          align: "start",
          value: "title",
        },
        { text: "Amount", align: "center", value: "amount" },
        { text: "Date", align: "center", value: "createdAt" },
      ],
    };
  },
  computed: {
    TableLoading() {
      return this.$store.getters.TableLoading;
    },
    inventory() {
      return this.$store.getters.inventory;
    },
    expense() {
      return this.$store.getters.expense;
    },
    getToken() {
      return this.$store.getters.getToken;
    },
    totalItems() {
      let total = this.$store.getters.totalItems;
      return Math.ceil(total / 20);
    },
  },
  methods: {
    DisplayTimeFormat(payload) {
      return moment(payload).format("DD MMM YYYY, LTS");
    },
    DisplayCurrency(payload) {
      return currencyFormatter.format(payload, {
        code: "NGN",
      });
    },
    getInventory() {
      let payload = {
        page: 1,
        token: this.getToken,
      };
      this.$store.dispatch("getInventory", payload);
    },
  },

  created() {
    this.getInventory();
  },

  watch: {
    tab: function (val) {
      if (val == 0) {
        this.status = "online";
        this.Currpage = 1;
        let payload = {
          page: 1,
          token: this.getToken,
        };
        this.$store.dispatch("getInventory", payload);
      }
      if (val == 1) {
        this.status = "offline";
        this.Currpage = 1;
        let payload = {
          page: 1,
          token: this.getToken,
        };
        this.$store.dispatch("getExpense", payload);
      }
      if (val == 2) {
        this.status = "online";
        this.Currpage = 1;
        let payload = {
          page: 1,
          token: this.getToken,
        };
        this.$store.dispatch("getAllInventory", payload);
      }
      if (val == 3) {
        this.status = "offline";
        this.Currpage = 1;
        let payload = {
          page: 1,
          token: this.getToken,
        };
        this.$store.dispatch("getAllExpenses", payload);
      }
    },
    page: function (val) {
      if (this.page != this.Currpage) {
        this.Currpage = val;
        let payload = {
          page: this.page,
          token: this.getToken,
        };
        if (this.tab == 0) {
          this.$store.dispatch("getInventory", payload);
        }
        if (this.tab == 1) {
          this.$store.dispatch("getExpense", payload);
        }
        if (this.tab == 2) {
          this.$store.dispatch("getAllInventory", payload);
        }
        if (this.tab == 3) {
          this.$store.dispatch("getAllExpenses", payload);
        }
        return;
      }
    },
  },
};
</script>