<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" persistent max-width="490">
      <template v-slot:activator="{ on, attrs }">
        <v-btn color="primary" dark v-bind="attrs" v-on="on">
          Assign Staff
        </v-btn>
      </template>
      <v-card>
        <v-card-title class="text-h5"> Assign To Staff </v-card-title>
        <v-simple-table dense>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">Name</th>
                <th class="text-left">Assign</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in getStaffs" :key="item.name">
                <td>
                  {{ item.personalInfo.firstName }}
                  {{ item.personalInfo.lastName }}
                </td>
                <td>
                  <v-icon v-if="item._id == assignTo" color="success" right>
                    mdi-checkbox-marked-circle
                  </v-icon>
                </td>
                <td>
                  <v-btn @click="assignTo = item._id" small>Assign</v-btn>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
        <div class="text-center pt-2">
          <v-pagination
            v-model="page"
            :length="ActivitiesTotalItems"
          ></v-pagination>
        </div>
        <v-card-actions>
          <v-btn color="green darken-1" text @click="dialog = false">
            Close
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="pink" dark @click="assignStaff"> Save </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
export default {
  props: ["inventoryId"],
  data() {
    return {
      dialog: false,
      assignTo: "",
      Currpage: 1,
      page: 1,
    };
  },
  methods: {
    assignStaff() {
      if (this.assignTo == "" || this.inventoryId == "") {
        this.$store.commit("setSnackbar", true);
        this.$store.commit(
          "setServerMessage",
          "Fill Out all the required fields"
        );
        return;
      }
      let payload = {
        token: this.getToken,
        staffId: this.assignTo,
        inventoryId: this.inventoryId,
      };
      this.$store.dispatch("assignStaffToInventory", payload);
      this.dialog = false;
    },
  },
  computed: {
    TableLoading() {
      return this.$store.getters.TableLoading;
    },
    getToken() {
      return this.$store.getters.getToken;
    },
    getStaffs() {
      return this.$store.getters.getStaffs;
    },
    ActivitiesTotalItems() {
      let total = this.$store.getters.totalItems;
      return Math.ceil(total / 20);
    },
  },
  watch: {
    dialog: function (val) {
      if (val == true) {
        let payload = {
          page: 1,
          token: this.getToken,
        };
        this.$store.dispatch("getAllStaffs", payload);
      }
    },
    page: function (val) {
      if (this.page != this.Currpage) {
        this.Currpage = val;
        let payload = {
          page: this.page,
          token: this.getToken,
        };
        this.$store.dispatch("getAllStaffs", payload);
        return;
      }
    },
  },
};
</script>