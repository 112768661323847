var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-card-actions',[_c('v-spacer'),_c('div',[_c('AddInventory',{staticClass:"ma-2"})],1),_c('div',[_c('AddExpenses',{staticClass:"ma-2"})],1)],1),_c('v-tabs',{attrs:{"background-color":"info","dark":""},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},_vm._l((_vm.items),function(item){return _c('v-tab',{key:item.tab},[_vm._v(" "+_vm._s(item.tab)+" ")])}),1),_c('v-tabs-items',{model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},_vm._l((_vm.items),function(item){return _c('v-tab-item',{key:item.content},[(_vm.tab == 0 || _vm.tab == 2)?_c('v-card',{attrs:{"flat":""}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.dessertHeaders,"items":_vm.inventory,"expanded":_vm.expanded,"item-key":"_id","show-expand":"","single-expand":true,"hide-default-footer":"","loading":_vm.TableLoading,"loading-text":"Loading... Please wait"},on:{"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"item.cost",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.DisplayCurrency(item.cost)))])]}},{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.DisplayTimeFormat(item.createdAt)))])]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{staticClass:"pa-4",attrs:{"colspan":headers.length}},[_c('div',[_vm._v(" "+_vm._s(item.description)+" ")]),_c('v-divider',{staticClass:"ma-2"}),_c('div',[_c('b',[_vm._v(" Added By : ")]),_vm._v(" "+_vm._s(item.addByStaff.personalInfo.firstName)+" "+_vm._s(item.addByStaff.personalInfo.lastName)+" ")]),_c('v-card-actions',[(item.assignTo)?_c('div',_vm._l((item.assignTo),function(assign,index){return _c('div',{key:index},[_c('b',[_vm._v(" Assign To : ")]),_vm._v(" "+_vm._s(assign.personalInfo.firstName)+" "+_vm._s(assign.personalInfo.lastName)+" "),_c('v-divider',{staticClass:"ma-1"})],1)}),0):_vm._e(),_c('v-spacer'),_c('div',[(_vm.tab == 0)?_c('AssignStaffToInventory',{attrs:{"inventoryId":item._id}}):_vm._e()],1)],1)],1)]}}],null,true)})],1):_vm._e(),(_vm.tab == 1 || _vm.tab == 3)?_c('v-card',{attrs:{"flat":""}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.expenseHeaders,"items":_vm.expense,"expanded":_vm.expanded,"item-key":"_id","show-expand":"","single-expand":true,"hide-default-footer":"","loading":_vm.TableLoading,"loading-text":"Loading... Please wait"},on:{"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"item.amount",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.DisplayCurrency(item.amount)))])]}},{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.DisplayTimeFormat(item.createdAt)))])]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{staticClass:"pa-4",attrs:{"colspan":headers.length}},[_c('div',[_vm._v(" "+_vm._s(item.description)+" ")]),_c('v-divider',{staticClass:"ma-2"}),_c('div',[_c('b',[_vm._v(" Added By : ")]),_vm._v(" "+_vm._s(item.addByStaff.personalInfo.firstName)+" "+_vm._s(item.addByStaff.personalInfo.firstName)+" ")]),(item.assignTo)?_c('div',[_c('b',[_vm._v(" Assign To : ")]),_vm._v(" "+_vm._s(item.assignTo.personalInfo.firstName)+" "+_vm._s(item.assignTo.personalInfo.firstName)+" ")]):_vm._e()],1)]}}],null,true)})],1):_vm._e()],1)}),1),_c('div',{staticClass:"text-center pt-2"},[_c('v-pagination',{attrs:{"length":_vm.totalItems},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }