<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" persistent max-width="600px">
      <template v-slot:activator="{ on, attrs }">
        <v-btn color="primary" dark v-bind="attrs" v-on="on">
          Add Inventory
        </v-btn>
      </template>
      <v-card>
        <v-card-title>
          <span class="text-h5"> Add Inventory</span>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="dialog = false">
            Close
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="6" md="6">
                <v-text-field
                  shaped
                  filled
                  label="Item Name*"
                  v-model="itemName"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-text-field
                  v-model="cost"
                  shaped
                  filled
                  label="Cost"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="12">
                <v-textarea
                  filled
                  auto-grow
                  label="Discription"
                  rows="4"
                  row-height="15"
                  v-model="discription"
                  shaped
                ></v-textarea>
                <small>*indicates required field</small>
              </v-col>
              <v-col cols="12">
                <div class="display-1 text-center font-weight-thin">
                  Assign To Staff
                </div>
                <div style="min-height: 4px">
                  <v-progress-linear
                    indeterminate
                    color="yellow darken-2"
                    v-if="TableLoading"
                  ></v-progress-linear>
                </div>
                <v-simple-table dense>
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-left">Name</th>
                        <th class="text-left">Assign</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="item in getStaffs" :key="item.name">
                        <td>
                          {{ item.personalInfo.firstName }}
                          {{ item.personalInfo.lastName }}
                        </td>
                        <td>
                          <v-icon
                            v-if="item._id == assignTo"
                            color="success"
                            right
                          >
                            mdi-checkbox-marked-circle
                          </v-icon>
                        </td>
                        <td>
                          <v-btn @click="assignTo = item._id" small
                            >Assign</v-btn
                          >
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
                <div class="text-center pt-2">
                  <v-pagination
                    v-model="page"
                    :length="ActivitiesTotalItems"
                  ></v-pagination>
                </div>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="dialog = false">
            Close
          </v-btn>
          <v-btn color="blue darken-1" text @click="AddInventory"> Save </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
export default {
  data: () => ({
    dialog: false,
    cost: "",
    itemName: "",
    discription: "",
    select: "",
    Currpage: 1,
    page: 1,
    assignTo: "",
  }),

  computed: {
    TableLoading() {
      return this.$store.getters.TableLoading;
    },
    getToken() {
      return this.$store.getters.getToken;
    },
    getStaffs() {
      return this.$store.getters.getStaffs;
    },
    ActivitiesTotalItems() {
      let total = this.$store.getters.totalItems;
      return Math.ceil(total / 20);
    },
  },

  methods: {
    AddInventory() {
      if (this.cost == "" || this.itemName == "") {
        this.$store.commit("setSnackbar", true);
        this.$store.commit(
          "setServerMessage",
          "Fill Out all the required fields"
        );
        return;
      }
      let payload = {
        token: this.getToken,
        cost: this.cost,
        itemName: this.itemName,
        assignTo: this.assignTo,
        description: this.description,
      };
      this.$store.dispatch("addInventory", payload);
      this.dialog = false;
      this.cost = "";
      this.itemName = "";
      this.assignTo = "";
      this.description = "";
    },
  },
  watch: {
    dialog: function (val) {
      if (val == true) {
        let payload = {
          page: 1,
          token: this.getToken,
        };
        this.$store.dispatch("getAllStaffs", payload);
      }
    },
    page: function (val) {
      if (this.page != this.Currpage) {
        this.Currpage = val;
        let payload = {
          page: this.page,
          token: this.getToken,
        };
        this.$store.dispatch("getAllStaffs", payload);
        return;
      }
    },
  },
};
</script>